import React from "react";
import Container from "Components/Container/Container";
import title from "Asset/title.png";
import logo from "Asset/logo-new.png";

const Home = () => {
  return (
    <main className="h-[100vh] max-h-[100vh] flex items-center bg-[#1F2B51] ">
      <Container>
        <div className="h-[100vh] lg:h-[98vh] lg:max-h-[98vh] flex flex-col items-center justify-center text-center">
          <p className="mt-4 md:mt-10 mb-6 md:mb-10 text-[#D59346] font-regular text-lg lg:text-3xl tracking-wider">
            {" "}
            The seductive want of the bon vivant, the masterpiece
          </p>
          <div className="max-h-full">
            <img src={title} alt="" className="h-full w-[20rem] md:w-[34rem]" />
          </div>

          <div className="max-h-full py-32 md:py-36 ">
            <img
              src={logo}
              alt=""
              className="h-48 lg:h-60 w-full object-contain"
            />
          </div>

          <div className="mt-4 md:mt-10 text-[#D59346] flex flex-col lg:gap-y-5 items-center justify-between">
            <p className=" text-lg md:text-3xl  font-regular ">
              For more information; kindly contact us on{" "}
            </p>
            <div className="flex flex-col md:flex-row items-center gap-x-6">
              <div className="flex gap-x-2  lg:gap-x-4">
                <span className="flex items-center gap-x-2">
                  <p className="font-bold text-lg md:text-2xl lg:text-3xl ">
                    Beirut
                  </p>
                  <a
                    href="tel:+9613473410"
                    rel="noreferrer"
                    target="_blank"
                    className="font-regular block text-lg  md:text-2xl lg:text-3xl tracking-wide"
                  >
                    +961(3) 473 410
                  </a>
                </span>
                <span className="flex items-center gap-x-2">
                  <p className="font-bold text-lg  md:text-2xl lg:text-3xl ">
                    Geneva
                  </p>
                  <a
                    href="tel:+41786421389"
                    rel="noreferrer"
                    target="_blank"
                    className="font-regular block text-lg   md:text-2xl lg:text-3x tracking-wide"
                  >
                    +41 78 642 13 89{" "}
                  </a>
                </span>
              </div>

              <div className="flex gap-x-2  lg:gap-x-4">
                <span>
                  <a
                    href="mailto:afranduliban@gmail.com"
                    rel="noreferrer"
                    target="_blank"
                    className="font-regular block text-lg  md:text-2xl lg:text-3x tracking-wide"
                  >
                    safranduliban@gmail.com
                  </a>
                </span>
                <span>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/safranduliban/?hl=en"
                    rel="noreferrer"
                    className="font-regular block text-lg  md:text-2xl lg:text-3x tracking-wide"
                  >
                    IG safranduliban
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Home;
